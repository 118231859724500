<script lang="ts">
import { useProject } from '@/composables/useProject'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { RouteParams } from '@/router/routeParams'
import { useStudyCaseStore } from '@/stores/study-case'
import { useSystemStore } from '@/stores/system'
import StudyCaseForm from '@/views/project/study-cases/StudyCaseForm.vue'
import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'StudyCaseDetail',
  components: { StudyCaseForm, DetailPanel },

  setup() {
    const studyCaseStore = useStudyCaseStore()
    const systemStore = useSystemStore()
    const { projectId } = useProject()
    systemStore.ensureLoaded(projectId.value)
    return { studyCaseStore, systemStore, projectId }
  },

  computed: {
    create(): boolean {
      return !this.$route.params[RouteParams.StudyCaseId]
    },

    title(): string {
      if (this.create) {
        return 'Neuer Berechnungsfall'
      } else {
        return 'Berechnungsfall bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-study-cases' }
      )
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose" style="width: 40rem">
    <StudyCaseForm v-if="systemStore.loadedProject == projectId" @cancel="onClose" />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
