<script setup lang="ts">
import SystemTag from '@/components/system/SystemTag.vue'
import { fieldsConductorState } from '@/config/fields/study-case'
import { ManualConfig } from '@/config/schemas/study-case'
import { SystemId } from '@/model'
import { useSystemStore } from '@/stores/system'
import { copy } from '@/util'
import type { OperationState } from '@gridside/hsb-api'
import { FieldEntry, useFieldArray } from 'vee-validate'
import { computed, nextTick } from 'vue'

const systemStore = useSystemStore()

// Get vee-validate context for this field array
const { fields, insert, remove } = useFieldArray<ManualConfig>('configuration.manual')

const AnglePresets = [
  [0, 180],
  [0, -120, 120]
] as const

const debug = false

const systemsUsed = computed(() =>
  fields.value
    .map((state) => systemStore.findById(state.value.system as SystemId))
    .filter((sys) => sys !== undefined)
)

const conductorColAmount = computed<number[]>(() =>
  Array.from({ length: Math.max(...systemsUsed.value.map((sys) => sys!.wireCount)) }).map(
    (_, i) => i
  )
)

const clockwiseAngles = (field: FieldEntry<OperationState>, index: number): boolean => {
  const angles: number[] = []
  const currents: number[] = []
  let lastAngle = 0

  for (const conductorState of field.value.conductorStates) {
    angles.push(conductorState.angle)
    currents.push(conductorState.current)
    // Find last angle where current is not zero
    if (conductorState.current > 0) {
      lastAngle = conductorState.angle
    }
  }

  // Step 1: Subtract the last angle from each angle and adjust values
  const adjustedAngles = angles.map((angle) => {
    let adjustedAngle = angle - lastAngle
    // Step 2: add  360° if below 0
    if (adjustedAngle < 0) {
      adjustedAngle = adjustedAngle + 360
    }
    return adjustedAngle
  })

  function angleBetween(angle1: number, angle2: number) {
    let angle = angle2 - angle1
    angle = ((angle % 360) + 360) % 360
    if (angle > 180) {
      angle -= 360
    }
    return angle
  }
  function isClockwise(angle1: number, angle2: number): boolean {
    return angleBetween(angle1, angle2) < 0
  }

  if (debug) {
    console.log('case ', index + 1, '-'.repeat(30))
    console.log('last angle', lastAngle)
    console.log('angles', angles)
    console.log('adjustedAngles', adjustedAngles)
    console.log('currents', currents)
  }

  // Step 3: 3 angles and AWE case
  if (adjustedAngles.length == 3 && isAWE(field)) {
    // Ignore cases where only one conductor has a current (Failure case)
    const nonZeroCurrentCount = currents.filter((c) => c !== 0).length
    if (nonZeroCurrentCount === 1) {
      return true
    }

    let angleBase = 0
    let angleNext = 0
    if (currents[0] === 0) {
      angleBase = adjustedAngles[1] // L2
      angleNext = adjustedAngles[2] // L3
    } else if (currents[1] === 0) {
      angleBase = adjustedAngles[2] // L3
      angleNext = adjustedAngles[0] // L1
    } else if (currents[2] === 0) {
      angleBase = adjustedAngles[0] // L1
      angleNext = adjustedAngles[1] // L2
    }
    if (debug) {
      console.log(
        'AWE comparing',
        angleBase,
        angleNext,
        'clockwise',
        isClockwise(angleBase, angleNext)
      )
    }
    if (!isClockwise(angleBase, angleNext)) {
      return false
    }
  } else {
    // Step 3: Check if the angles are in descending order
    for (let i = 0; i < adjustedAngles.length; i++) {
      if (adjustedAngles[i + 1] >= adjustedAngles[i]) {
        return false // Not in descending order
      }
    }
  }

  return true // All angles are in descending order
}

const cycleConductorStates = (field: FieldEntry<OperationState>) => {
  if (field.value.conductorStates.length) {
    const last = field.value.conductorStates.pop()
    if (last) {
      field.value.conductorStates.unshift(last)
    }
  }
}
/**
 * AWE when any wire/phase has a current of zero
 */
const isAWE = (field: FieldEntry<OperationState>) => {
  return field.value.conductorStates.some((item) => item.current == 0)
}

const mirrorCurrents = (field: FieldEntry<ManualConfig>) => {
  // use next tick because this can be triggered
  // before model value has actually updated in current flush
  nextTick(() => {
    const current = field.value.conductorStates[0].current
    field.value.conductorStates.forEach((conductorState) => (conductorState.current = current))
  })
}

const toggleMirror = (field: FieldEntry<ManualConfig>) => {
  field.value.mirrored = !field.value.mirrored
  if (field.value.mirrored) {
    mirrorCurrents(field)
    const anglePreset = AnglePresets.find(
      (preset) => preset.length === field.value.conductorStates.length
    )
    field.value.conductorStates.forEach((conductorState, index) => {
      conductorState.angle = anglePreset?.[index] ?? 0
    })
  }
}
</script>

<template>
  <div
    class="grid gap-x-2 gap-y-2"
    :style="`grid-template-columns: 3ch auto repeat(${conductorColAmount.length}, minmax(56px, 1fr)) 4ch 4ch`"
  >
    <!-- HEADER ROW -->
    <div class="table-header">Nr.</div>
    <div class="table-header">System</div>
    <div v-for="i in conductorColAmount" :key="i" class="table-header">L{{ i + 1 }}</div>
    <div class="table-header col-span-2">Aktionen</div>

    <!-- OPERATION STATES -->
    <template v-for="(field, fieldIndex) in fields" :key="`${fieldIndex}-${field.value.system}`">
      <hr :style="`grid-column: span ${conductorColAmount.length + 4}`" class="!my-2" />

      <!-- System Tag -->
      <div class="row-span-2">
        <SystemTag :system="field.value.system" type="small" class="p-0.5 text-xs">
          {{ fieldIndex + 1 }}
        </SystemTag>
      </div>
      <!-- System name -->
      <div
        class="font-bold text-sm row-span-2 overflow-hidden overflow-ellipsis pt-0.5 min-w-[192px]"
      >
        {{ systemStore.systemName(field.value.system) }}
      </div>

      <!-- Currents -->
      <div v-for="colCondIndex in conductorColAmount" :key="colCondIndex">
        <p-field
          v-if="field.value.conductorStates[colCondIndex]"
          :disabled="field.value.mirrored && colCondIndex > 0"
          dense
          v-bind="fieldsConductorState.current"
          :name="`configuration.manual.${fieldIndex}.conductorStates.${colCondIndex}.${fieldsConductorState.current.name}`"
          label=""
          size="small"
          @update:modelValue="field.value.mirrored ? mirrorCurrents(field) : null"
        />
      </div>

      <!-- Action: Cycle Conductors -->
      <div class="text-center pl-2">
        <el-button
          class="!p-0"
          text
          circle
          size="small"
          :disabled="field.value.mirrored"
          title="um eine Phase verschieben"
          @click="cycleConductorStates(field)"
        >
          <RotateIcon />
        </el-button>
      </div>

      <!-- Action: Remove -->
      <div class="text-center">
        <el-button class="!p-0" size="small" text circle @click="remove(fieldIndex)">
          <RemoveIcon />
        </el-button>
      </div>

      <!-- Phase Angles -->
      <div v-for="colCondIndex in conductorColAmount" :key="colCondIndex">
        <p-field
          :disabled="field.value.mirrored"
          dense
          v-bind="fieldsConductorState.angle"
          :name="`configuration.manual.${fieldIndex}.conductorStates.${colCondIndex}.${fieldsConductorState.angle.name}`"
          label=""
          size="small"
        />
      </div>

      <!-- Action: Mirror -->
      <div class="text-center pl-2">
        <el-button
          text
          title="symmetrische Phasenfolge"
          circle
          size="small"
          :type="field.value.mirrored ? 'primary' : ''"
          @click="toggleMirror(field)"
        >
          <MirrorIcon />
        </el-button>
      </div>

      <!-- Action: Duplicate -->
      <div class="text-center">
        <el-button
          text
          circle
          size="small"
          title="duplizieren"
          @click="insert(fieldIndex, copy(field.value))"
        >
          <el-icon size="20">
            <Duplicate2Icon />
          </el-icon>
        </el-button>
      </div>

      <!-- Clockwise angle warning -->
      <template v-if="!clockwiseAngles(field, fieldIndex)">
        <!-- Conductors -->
        <div :style="`grid-column: 3 / span ${conductorColAmount.length}`">
          <el-alert type="warning" :closable="false" class="!px-2 !py-1">
            <p class="text-xs font-medium">
              {{
                isAWE(field) && conductorColAmount.length == 3
                  ? 'Eingabe ist elektrotechnisch nicht sinnvoll.'
                  : 'Phasenfolge ist nicht rechtsdrehend.'
              }}
            </p>
          </el-alert>
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="css">
.table-header {
  @apply text-gray-500 text-sm font-semibold text-center -mb-3;
}

:deep(.p-field__error) {
  @apply text-xs;
}
</style>
