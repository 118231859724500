<script setup lang="ts">
import frontendInfo from '@/../build/version-info.json'
import useAppHealth from '@/composables/useAppHealth'
import { getEnv } from '@/util'

const backendUrl = getEnv('VITE_REST_BASE_URL')

const { versionInfo: backendInfo } = useAppHealth()
</script>

<template>
  <div class="text-center text-xs version-info cursor-default">
    <div class="version-info__trigger text-gray-400">
      HSB GUI Version: {{ frontendInfo.tag || frontendInfo.branch }}
      {{
        $d(frontendInfo.timestamp ? new Date(frontendInfo.timestamp) : new Date(), {
          dateStyle: 'medium'
        })
      }}
    </div>
    <div class="version-info__details text-gray-400">
      HSB GUI Frontend: {{ frontendInfo.tag || frontendInfo.branch || 'unbekannt' }} ({{
        $d(frontendInfo.timestamp ? new Date(frontendInfo.timestamp) : new Date(), {
          dateStyle: 'medium',
          timeStyle: 'short'
        })
      }})
      <template v-if="backendInfo">
        -
        <span :title="`URL: ${backendUrl}`">Backend:</span>
        {{ backendInfo.tag || backendInfo.branch || 'unbekannt' }}
        <template v-if="backendInfo.timestamp">
          ({{ $d(new Date(backendInfo.timestamp), { dateStyle: 'medium', timeStyle: 'short' }) }})
        </template>
      </template>
      <template v-if="backendInfo?.hsblib">- HSBlib: {{ backendInfo?.hsblib }}</template>
      <br />
      API URL: {{ backendUrl }}
    </div>
  </div>
</template>

<style scoped lang="css">
.version-info__details {
  display: none;
}

.version-info:hover .version-info__details {
  display: block;
}
.version-info:hover .version-info__trigger {
  display: none;
}
</style>
