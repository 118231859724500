import { FieldConfig } from '@prionect/ui'
import { OverheadLine } from '@gridside/hsb-api'

const fields: Record<
  keyof Pick<Required<OverheadLine>, 'name' | 'description' | 'operator'>,
  FieldConfig
> = {
  name: {
    name: 'name',
    label: 'Name',
    required: true
  },
  description: {
    name: 'description',
    type: 'textarea',
    label: 'Beschreibung'
  },
  operator: {
    name: 'operator',
    label: 'Betreiber'
  }
} as const

export default fields
