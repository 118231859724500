<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import { useProject } from '@/composables/useProject'
import { predefinedColors, useSystemColor } from '@/composables/useSystemColor'
import { system } from '@/config/fields'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { System, SystemId } from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useSystemStore } from '@/stores/system'
import { copy, int } from '@/util'
import { ElMessage } from 'element-plus'
import { v4 } from 'uuid'
import { computed, defineComponent } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'

export default defineComponent({
  name: 'SystemForm',
  components: { ConductorTypeSelectField, DetailPanel },

  setup() {
    const systemStore = useSystemStore()
    const route = useRoute()
    const { projectId } = useProject()

    const id = computed<SystemId>(() => {
      return route.params[RouteParams.SystemId] as string
    })

    const item = computed<System>(() => {
      const storeItem = systemStore.findById(id.value)
      if (storeItem) {
        // Kill object references, otherwise vee-validate mutates object (we don't want that)
        return copy(storeItem)
      }
      // default for draft
      const newId = v4()
      return {
        id: newId,
        name: '',
        conductorTypes: [],
        project: projectId.value,
        wireCount: 3,
        isolatorLength: 0,
        color: useSystemColor(newId)
      }
    })

    return {
      conductorTypeStore: useConductorTypeStore(),
      predefinedColors,
      id,
      item,
      projectId,
      systemStore
    }
  },

  data: () => ({
    fieldConfig: system,
    maxWireCount: 3,
    conductorTypeFields: [],
    saving: false,
    showColorPicker: false
  }),

  computed: {
    create(): boolean {
      return !this.id
    }
  },

  mounted() {
    this.conductorTypeStore.ensureLoaded()
    this.conductorTypeStore.ensureLoadedByProject(this.projectId)
  },

  methods: {
    int,
    asSystem(item: any): System {
      return item as System
    },

    async save(values: System) {
      // sometimes, the conductorTypes array contains undefined items
      const data: System = {
        ...values,
        conductorTypes: values.conductorTypes.filter((c) => c !== undefined)
      }
      this.saving = true

      try {
        const savedItem = await this.systemStore.save(data)
        if (this.create) {
          ElMessage.success('System wurde erfolgreich angelegt.')
          this.$router.push({ name: 'project-system-edit', params: { systemId: savedItem.id } })
        } else {
          ElMessage.success('Daten wurden erfolgreich gespeichert.')
        }
      } finally {
        this.saving = false
      }
    },

    onClose() {
      this.systemStore.selection = []
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-systems' }
      )
    }
  }
})
</script>

<template>
  <DetailPanel :title="create ? 'Neues System' : 'System bearbeiten'" @close="onClose">
    <p-form
      v-if="systemStore.loaded"
      v-slot="{ values }"
      :item="item"
      cancelable
      :saving="saving"
      @cancel="onClose"
      @submit="save"
    >
      <p-field v-bind="fieldConfig.name" />
      <div class="grid grid-cols-2 gap-x-4">
        <p-field v-bind="fieldConfig.operator" />
        <p-field v-bind="fieldConfig.voltageLevel" clearable />
        <p-field v-bind="fieldConfig.wireCount" />
        <p-field v-bind="fieldConfig.frequency" />
        <p-field v-bind="fieldConfig.isolatorLength" />
        <p-field v-bind="fieldConfig.color">
          <template #input="ctx">
            <div class="flex gap-2">
              <PTextInput disabled :modelValue="ctx.field.value" />
              <el-color-picker
                :model-value="ctx.field.value"
                size="large"
                color-format="hex"
                :predefine="predefinedColors"
                @change="ctx.handleChange"
              />
            </div>
          </template>
        </p-field>
      </div>

      <p-form-section title="Leitertypen" class="!mt-0">
        <table v-if="asSystem(values).conductorTypes" class="w-full my-4">
          <!-- make sure we definitely handle integers... -->
          <tr v-for="i in int(asSystem(values).wireCount) || 1" :key="i">
            <td class="align-top pt-2 pr-3 font-bold text-gray-500">L{{ i }}</td>
            <td class="pb-2">
              <ConductorTypeSelectField
                :field-config="fieldConfig.conductorTypes"
                :index="i - 1"
                :conductors="asSystem(values).conductorTypes"
              />
            </td>
          </tr>
        </table>
      </p-form-section>
    </p-form>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
