<script lang="ts">
import { useProject } from '@/composables/useProject'
import { studyCase } from '@/config/fields'
import { StudyCaseConfigSchema, StudyCaseSchemaBase } from '@/config/schemas/study-case'
import {
  CalculationModes,
  getValidStudyCaseWithConfig,
  StudyCaseId,
  StudyCaseWithConfig,
  SuperpositionPreset
} from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useCalculationStore } from '@/stores/calculation'
import { useStudyCaseStore } from '@/stores/study-case'
import { useSystemStore } from '@/stores/system'
import { copy } from '@/util'
import { useOperationStatesDraft } from '@/views/project/study-cases/operation-states/draft-functions'
import StudyCaseOperationStatesTab from '@/views/project/study-cases/operation-states/StudyCaseOperationStatesTab.vue'
import StudyCaseBaseTab from '@/views/project/study-cases/StudyCaseBaseTab.vue'
import StudyCaseSuperpositionTab from '@/views/project/study-cases/superposition/StudyCaseSuperpositionTab.vue'
import { generateSuperpositionPresetExpression } from '@/views/project/study-cases/superposition/util'
import type { TabConfig } from '@prionect/ui/dist/types/components/tabs/PTabs.vue'
import { toTypedSchema } from '@vee-validate/zod'
import { ElMessage } from 'element-plus'
import { useForm } from 'vee-validate'
import { computed, defineComponent, nextTick, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'StudyCaseForm',
  components: {
    StudyCaseBaseTab,
    StudyCaseOperationStatesTab,
    StudyCaseSuperpositionTab
  },

  data: () => ({
    CalculationModes,
    fieldConfig: studyCase,
    saving: false,
    starting: false
  }),

  emits: {
    cancel: (val: any) => val
  },

  setup(_, { emit }) {
    const studyCaseStore = useStudyCaseStore()
    const systemStore = useSystemStore()
    const calculationStore = useCalculationStore()
    const { projectId } = useProject()

    studyCaseStore.ensureLoaded(projectId.value)
    systemStore.ensureLoaded(projectId.value)
    calculationStore.ensureLoaded(projectId.value)

    const tabs = ref<TabConfig[]>([
      { id: 'base', label: 'Allgemein', error: false },
      { id: 'operationStates', label: 'Betriebszustände', error: false },
      { id: 'superposition', label: 'Überlagerung', error: false }
    ])

    /**
     * Setup route item
     */
    const route = useRoute()
    const router = useRouter()
    const routeId = computed(() => route.params[RouteParams.StudyCaseId] as StudyCaseId | undefined)
    const routeItem = computed(() =>
      routeId.value ? studyCaseStore.findById(routeId.value) : undefined
    )

    /**
     * Setup vee-validate Form
     * - merge initial values with default values
     * - setup dynamic validation based on selected calculationMode
     * (Attention: "validationSchema" overrides rules on <Field>)
     */
    const initialValues = getValidStudyCaseWithConfig(routeItem.value)
    const calculationMode = ref<CalculationModes>(initialValues.configuration.modeSelected)

    const validationSchema = computed(() => {
      const schema = StudyCaseSchemaBase.extend({
        configuration: StudyCaseConfigSchema
      })
      return toTypedSchema(schema)
    })

    const studyCaseForm = useForm<StudyCaseWithConfig>({
      initialValues,
      validationSchema,
      keepValuesOnUnmount: true // because of switching modes
    })

    const [superposition] = studyCaseForm.defineField('superposition')

    const operationStatesDraft = useOperationStatesDraft(studyCaseForm)

    // Make accessible in child components
    provide('studyCaseForm', studyCaseForm)
    provide('operationStatesDraft', operationStatesDraft)

    /**
     * Handle tab errors
     */
    watch(studyCaseForm.errors, (value) => {
      // don't show errors when never touched
      if (!studyCaseForm.meta.value.touched) {
        return
      }
      const errors = Object.keys(value)
      tabs.value[2].error = errors.some((key) => key.startsWith('superposition'))
      tabs.value[1].error = errors.some((key) => key.startsWith('configuration'))
    })

    /**
     * Load StudyCase when route param changes.
     * Use resetForm + force to reset initial values instead of merging
     * See: https://vee-validate.logaretm.com/v4/api/use-form/#api-reference
     */
    watch(routeItem, (value, prev) => {
      const studyCaseWithConfig = getValidStudyCaseWithConfig(value)
      studyCaseForm.resetForm({ values: studyCaseWithConfig }, { force: true })
    })

    /**
     * Form submission handler
     */
    const submitForm = async (omitSuccessMessage = false) => {
      await studyCaseForm.handleSubmit(async (values) => {
        const studyCase: StudyCaseWithConfig = copy(values)

        // Apply draft value to final operation states
        studyCase.operationStates = operationStatesDraft.value

        // Sync generated operation states to manual
        studyCase.configuration.manual = [...studyCase.operationStates]
        studyCaseForm.setFieldValue('operationStates', studyCase.operationStates)

        // Update superposition expression (unless custom)
        if (studyCase.configuration.superpositionPreset !== SuperpositionPreset.CUSTOM) {
          studyCase.superposition = generateSuperpositionPresetExpression(
            studyCase.configuration.superpositionPreset,
            studyCase.operationStates
          )
        }

        // Actually save the item
        const updated = await studyCaseStore.save(studyCase)

        // reselect item (because it gets deselected by <el-table>...)
        nextTick(() => (studyCaseStore.selection = [updated.id]))

        const isCreateMode = !route.params[RouteParams.StudyCaseId]
        if (isCreateMode) {
          router.push({ name: 'project-study-case-edit', params: { studyCaseId: updated.id } })
        }

        if (!omitSuccessMessage) {
          ElMessage.success(
            isCreateMode
              ? 'Berechnungsfall wurde erfolgreich angelegt.'
              : 'Daten wurden erfolgreich gespeichert.'
          )
        }
      })()
    }

    return {
      calculationMode,
      calculationStore,
      operationStatesDraft,
      projectId,
      routeItem,
      submitForm,
      superposition,
      studyCaseStore,
      studyCaseForm,
      tabs,
      validationSchema
    }
  },

  computed: {
    formValid() {
      return this.studyCaseForm.meta.value.valid
    }
  },

  methods: {
    copy,

    async onSave() {
      this.saving = true
      try {
        await this.submitForm()
      } finally {
        this.saving = false
      }
    },

    async onStart() {
      if (!this.routeItem) {
        return
      }

      this.starting = true
      try {
        // save before start
        await this.submitForm(true)
        const calculation = await this.calculationStore.start(this.projectId, this.routeItem.id)
        await this.$router.push({
          name: 'project-map',
          query: { calculationId: calculation.id, studyCaseId: this.routeItem.id }
        })
      } finally {
        this.starting = false
      }
    }
  }
})
</script>

<template>
  <form v-if="studyCaseStore.loaded" @submit="onSave">
    <p-field v-bind="fieldConfig.name" />

    <p-tabs :tabs="tabs">
      <!-- Base properties -->
      <template #tab:base>
        <StudyCaseBaseTab />
      </template>

      <!-- Operation States -->
      <template #tab:operationStates>
        <StudyCaseOperationStatesTab />
      </template>

      <!-- superposition -->
      <template #tab:superposition>
        <StudyCaseSuperpositionTab
          v-model="superposition"
          :operation-states="studyCaseForm.values.operationStates"
        />
      </template>
    </p-tabs>

    <div class="py-4 flex">
      <el-button type="success" :loading="saving" @click="onSave">Speichern</el-button>
      <el-button text @click="$emit('cancel', true)">Abbrechen</el-button>
      <div class="flex-grow"></div>
      <el-button
        :disabled="!formValid || routeItem === undefined"
        :loading="starting"
        @click="onStart"
      >
        Speichern und berechnen
      </el-button>
    </div>

    <el-alert
      v-if="studyCaseForm.submitCount.value > 0 && !studyCaseForm.meta.value.valid"
      type="error"
      :closable="false"
    >
      <p class="font-semibold list-disc list">
        Der Berechnungsfall kann nicht gespeichert werden. Bitte beheben Sie die oben angezeigten
        Fehler:
      </p>

      <ul class="list-disc pl-8 space-y-1 py-2">
        <li v-for="error in studyCaseForm.errors.value" :key="error">{{ error }}</li>
      </ul>
    </el-alert>
  </form>
</template>
<style scoped></style>
