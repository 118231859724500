import { FieldConfig, FieldConfigs } from '@prionect/ui'
import { System } from '@/model'

const fields = {
  name: {
    name: 'name',
    label: 'Name',
    required: 'Bitte geben Sie einen Namen ein.'
  },
  color: {
    name: 'color',
    type: 'text',
    label: 'Farbe',
    required: 'Bitte wählen Sie eine Farbe aus',
    placeholder: 'Farbe'
  },
  conductorTypes: {
    name: 'conductorTypes',
    type: 'select',
    placeholder: 'Leitertyp wählen',
    required: 'Bitte wählen Sie einen Typ aus.',
    items: [] // will be added at runtime
  },
  frequency: {
    name: 'frequency',
    label: 'Frequenz',
    type: 'number',
    required: true,
    unit: 'Hz',
    min: 0
  },
  isolatorLength: {
    name: 'isolatorLength',
    type: 'number',
    label: 'Isolatorlänge',
    placeholder: 'Isolatorlänge',
    unit: 'm',
    min: 0,
    step: 0.01
  },
  operator: {
    name: 'operator',
    label: 'Betreiber'
  },
  voltageLevel: {
    name: 'voltageLevel',
    label: 'Spannungsebene',
    type: 'select',
    items: [
      { value: 110, label: '110 kV' },
      { value: 220, label: '220 kV' },
      { value: 380, label: '380 kV' }
    ]
  },
  wireCount: {
    name: 'wireCount',
    type: 'select',
    items: [1, 2, 3],
    label: 'Anzahl Leiter',
    required: true
  }
} satisfies Record<keyof Omit<System, 'id' | 'project'>, FieldConfig>

export default fields
