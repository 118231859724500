<script setup lang="ts">
import SystemSelector from '@/components/study-case/SystemSelector.vue'
import SystemTag from '@/components/system/SystemTag.vue'
import { fieldsFailConfig } from '@/config/fields/study-case'
import { AutoFailConfig } from '@/config/schemas/study-case'
import { StudyCaseWithConfig } from '@/model'
import { useSystemStore } from '@/stores/system'
import { System } from '@gridside/hsb-api'
import { FormContext, useFieldArray } from 'vee-validate'
import { inject } from 'vue'

const systemStore = useSystemStore()
const form = inject<FormContext<StudyCaseWithConfig>>('studyCaseForm')
const { fields, remove, push } = useFieldArray<AutoFailConfig>('configuration.autoFail')

const addSystem = (system: System) => {
  push({ system: system.id, current: 0 })
}
</script>

<template>
  <p-form-section title="Betrachtete Systeme und Fehlerströme">
    <p class="text-gray-400 mb-4">
      Wählen Sie Systeme aus und legen Sie für jedes System den Fehlerstrom fest. Es werden
      Betriebszustände erzeugt, bei denen jeweils ein Leiter den angegebenen Fehlerstrom führt.
    </p>

    <div
      v-if="fields.length > 0"
      class="grid gap-2 gap-x-3"
      :style="`grid-template-columns: 3ch minmax(200px, auto) minmax(80px, 120px) 4ch`"
    >
      <div class="table-header"></div>
      <div class="table-header">System</div>
      <div class="table-header">Fehlerstrom</div>
      <div class="table-header"></div>

      <template
        v-for="(rowField, rowOpIndex) in fields"
        :key="`${rowOpIndex}-{rowField.value.system}`"
      >
        <hr class="!my-2 col-span-4" />

        <!-- System Tag -->
        <SystemTag :system="rowField.value.system" type="small" class="w-1 h-5 mt-0.5"></SystemTag>

        <!-- System name -->
        <div class="font-bold text-sm overflow-hidden overflow-ellipsis pt-1">
          {{ systemStore.systemName(rowField.value.system) }}
        </div>

        <div>
          <p-field
            v-bind="fieldsFailConfig.current"
            :name="`configuration.autoFail.${rowOpIndex}.${fieldsFailConfig.current.name}`"
            label=""
            dense
            size="small"
          />
        </div>

        <div class="text-center pt-px">
          <!-- Remove -->
          <el-button size="small" text circle @click="remove(rowOpIndex)">
            <RemoveIcon />
          </el-button>
        </div>
      </template>
    </div>

    <SystemSelector
      class="mt-4"
      :has-error="fields.length === 0 && form?.meta.value.touched"
      @click="addSystem"
    />
  </p-form-section>
</template>

<style scoped>
.table-header {
  @apply text-gray-500 text-sm font-semibold text-center -mb-3;
}

:deep(.p-field__error) {
  @apply text-xs;
}
</style>
