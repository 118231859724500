<script lang="ts">
import TowerSVGHoverInformation from '@/components/tower/TowerSVGHoverInformation.vue'
import { useSystemColor } from '@/composables/useSystemColor'
import {
  factorInIsolatorLength,
  hasConductorSystemData,
  WirePoint,
  WirePositionLabeled,
  WirePositionLabeledConductors
} from '@/model'
import { conductorLabelFn } from '@/util'
import { createVirtualRef } from '@/util/helpers'
import { MIXED_VALUES } from '@prionect/ui'
import anime from 'animejs'
import { defineComponent, PropType } from 'vue'
import { LineTowerType } from '@gridside/hsb-api/dist/models/LineTowerType'

export default defineComponent({
  name: 'TowerSVG',
  components: { TowerSVGHoverInformation },
  setup() {
    const tooltipVirtualRef = createVirtualRef()
    return {
      tooltipVirtualRef
    }
  },
  props: {
    wireData: {
      type: Array as PropType<WirePoint[]>,
      default: () => []
    },
    /**
     * How much the whole tower is lifted
     */
    towerOffset: {
      type: [Number, String] as PropType<number | null | typeof MIXED_VALUES>,
      default: 0
    },
    lineTowerType: {
      type: String as PropType<LineTowerType>
    },
    caption: {
      type: String as PropType<string | null>,
      default: () => null
    },
    maxHeight: {
      type: String as PropType<string>,
      required: false,
      default: '300px'
    },
    limitHeight: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    showTowerOffset: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  },
  data() {
    return {
      svgHovered: false,

      tooltipVisible: false,
      tooltipWirePoint: null as null | WirePoint,

      lineWidth: 1.5,
      lineWidthCoordinates: 0.1,
      lineColor: 'rgb(59,64,80)',
      lineColorTowerOffset: 'rgb(153,153,153)',
      lineColorIsolator: 'rgb(153,153,153)',
      lineColorEarthwire: 'rgb(107,114,128)',
      lineIsolatorHeight: -4,

      coordinatePadding: 3, // should be larger than lineEdgeOffset * -1

      axisLinesColor: 'rgba(0,0,0,0.27)',
      axisResolution: 10,
      axisLabelFontSize: 2.5,

      zoomSpeed: 0.1, // should be between 0 and 1
      zoomMaxFactor: 2, // 2 means 4x the area of viewBoxBase
      zoomLimit: Infinity as number,

      // SVG Viewbox
      viewBox: {
        top: 0,
        left: 0,
        width: 0,
        height: 0
      },

      // <g> inside SVG where 0.0 is bottom center
      coordinateOffset: {
        x: 0,
        y: 0
      },

      // Dragging feature
      dragging: false,
      lastMousePosition: { x: 0, y: 0 },
      conductorLabelFn,
      hasConductorSystemData
    }
  },

  watch: {
    filteredAndSortedWireData: {
      handler() {
        this.setDefaultView()
      },
      immediate: true
    },
    towerOffset() {
      // tbh this is just a fix for the axis calculation when towerOffset changes...
      this.setDefaultView()
    }
  },
  computed: {
    /**
     * Returns wire data after filtering out items with undefined positions
     * and sorting the remaining items in ascending order by their 'x' value.
     */
    filteredAndSortedWireData(): WirePoint[] {
      const filterUndefinedPositions = (item: WirePositionLabeled) => {
        return item.y != undefined && item.x != undefined
      }
      // since we cannot use z-Index inside SVG we need to make sure
      // we prepare the list-items in the correct order for the desired layering
      const sortAscendingByX = (a: WirePositionLabeled, b: WirePositionLabeled) => {
        return a.x - b.x
      }
      return this.wireData.filter(filterUndefinedPositions).sort(sortAscendingByX)
    },
    conductorPositions(): (WirePositionLabeled | WirePositionLabeledConductors)[] {
      return this.filteredAndSortedWireData.filter((item) => item.type !== 'earthwire')
    },
    /**
     * List of x-Axis values
     */
    xAxisScale(): number[] {
      const left = this.roundedToFloor(this.viewBox.left - this.axisResolution)
      const right = this.roundedToCeil(this.viewBox.left + this.viewBox.width + this.axisResolution)
      return this.computeAxisSteps(left, right)
    },

    xAxisMinMax() {
      const min = Math.min(...this.xAxisScale)
      const max = Math.max(...this.xAxisScale)
      return {
        min: isFinite(min) ? min : 0,
        max: isFinite(max) ? max : 0
      }
    },

    /**
     * List of y-Axis values
     */
    yAxisScale(): number[] {
      const top = this.roundedToCeil(
        this.baseFrustum.top -
          (this.baseFrustum.top - this.viewBox.top) * -1 +
          this.axisResolution +
          this.coordinatePadding
      )
      // not drawn below 0
      const bottom = 0
      return this.computeAxisSteps(bottom, top)
    },
    yAxisMinMax() {
      const min = Math.min(...this.yAxisScale)
      const max = Math.max(...this.yAxisScale)
      return {
        min: isFinite(min) ? min : 0,
        max: isFinite(max) ? max : 0
      }
    },

    /**
     * Upper end of tower
     */
    towerTop() {
      let top = 0
      const left = this.highestConductorPositions.left
      const right = this.highestConductorPositions.right
      if (left) {
        top = left.y
      }
      if (right && right.y > top) {
        top = right.y
      }
      return top + this.lineWidth * 0.5
    },
    towerOffsetNumber() {
      const number = Number(this.towerOffset)
      if (!isNaN(number)) {
        return number
      }
      return 0
    },
    /**
     * Return the highest point of left and right side of tower
     */
    highestConductorPositions() {
      let left: WirePoint | null = null
      let right: WirePoint | null = null
      for (const wireData of this.conductorPositions) {
        if (wireData.x > 0) {
          if (!right) {
            right = wireData
            continue
          }
          if (wireData.y > right.y) {
            right = wireData
          }
        } else {
          if (!left) {
            left = wireData
            continue
          }
          if (wireData.y > left.y) {
            left = wireData
          }
        }
      }
      return {
        right,
        left
      }
    },

    /**
     * Bounding box of the coords given
     */
    boundingBox() {
      const ys: number[] = [0]
      const xs: number[] = [0]

      this.filteredAndSortedWireData.forEach((coord) => {
        ys.push(coord.y)
        xs.push(coord.x)
      })

      return {
        top: Math.max(...ys),
        right: Math.max(...xs),
        bottom: Math.min(...ys),
        left: Math.min(...xs)
      }
    },

    /**
     * Based on bounding box creates a base frustum.
     * It is required that min y contains 0 to always display the base of the tower
     */
    baseFrustum() {
      const bb = this.boundingBox

      // Push default minimum view
      const offset = this.towerOffsetNumber
      const xs = [-25, 25, bb.left, bb.right]
      const ys = [0, 50, bb.top + offset, bb.bottom + offset]

      return {
        top: Math.max(...ys),
        right: Math.max(...xs),
        bottom: Math.min(...ys),
        left: Math.min(...xs)
      }
    },
    limitHeightStyle() {
      return this.limitHeight ? { height: '100%' } : {}
    }
  },
  methods: {
    diff(a: number, b: number) {
      return Math.abs(a - b)
    },
    roundedToCeil(val: number) {
      return Math.ceil(val / this.axisResolution) * this.axisResolution
    },
    roundedToFloor(val: number) {
      return Math.floor(val / this.axisResolution) * this.axisResolution
    },
    systemColor(point: WirePositionLabeledConductors): string {
      if (point.conductorSystemData && point.conductorSystemData.system) {
        return useSystemColor(point.conductorSystemData.system)
      }
      return '#cccccc'
    },
    setDefaultView() {
      const frustum = this.baseFrustum
      const largestAmplitude = Math.max(Math.abs(frustum.right), Math.abs(frustum.left))

      // Sets initial SVG Values
      const left = frustum.left
      const top = frustum.top
      const width = largestAmplitude * 2 + this.coordinatePadding * 2
      const height = this.diff(frustum.top, frustum.bottom) + this.coordinatePadding

      // Based on default viewBox calculate the zoom limit
      this.zoomLimit = width * height * Math.pow(this.zoomMaxFactor, 2)

      anime({
        targets: this.viewBox,
        left,
        top,
        width,
        height,
        duration: 150,
        easing: 'easeOutQuad'
      })

      this.coordinateOffset.x = width * 0.5 - frustum.right
      this.coordinateOffset.y =
        frustum.top + height + frustum.bottom - this.lineWidthCoordinates * 3
    },

    computeAxisSteps(start: number, end: number) {
      const axis: number[] = []

      // Start cannot be bigger than end
      if (start > end) {
        console.info('Start cannot be bigger than end')
        return axis
      }
      for (let i = start; i < end; i += this.axisResolution) {
        axis.push(i)
      }
      return axis
    },
    handleMouseover(e: MouseEvent) {
      // Only SVGCircle elements
      if (!(e.target instanceof SVGCircleElement)) {
        return
      }
      const circle = e.target
      this.tooltipVirtualRef.position = circle.getBoundingClientRect()
      this.tooltipVisible = true
    },
    handleWheel(event: WheelEvent) {
      event.preventDefault()
      const svg = this.$refs.svg as SVGElement | undefined
      if (!svg) {
        return
      }
      // Mauskoordinaten innerhalb der SVG berechnen
      const svgRect = svg.getBoundingClientRect()
      const mouseX = event.clientX - svgRect.left
      const mouseY = event.clientY - svgRect.top

      // Mauskoordinaten im viewBox-Koordinatensystem berechnen
      const viewBoxMouseX = (mouseX * this.viewBox.width) / svgRect.width + this.viewBox.left
      const viewBoxMouseY = (mouseY * this.viewBox.height) / svgRect.height + this.viewBox.top

      const direction = event.deltaY > 0 ? -1 : 1
      const scalingFactor = 1 - direction * this.zoomSpeed

      // viewBox-Werte aktualisieren
      const newWidth = this.viewBox.width * scalingFactor
      const newHeight = this.viewBox.height * scalingFactor

      // limit Zoom
      if (newWidth * newHeight > this.zoomLimit) {
        return
      }
      this.viewBox.height = newHeight
      this.viewBox.width = newWidth
      this.viewBox.left += (viewBoxMouseX - this.viewBox.left) * (1 - scalingFactor)
      this.viewBox.top += (viewBoxMouseY - this.viewBox.top) * (1 - scalingFactor)
    },
    handlePointerDown(event: PointerEvent) {
      this.dragging = true
      this.lastMousePosition = { x: event.clientX, y: event.clientY }

      // Deploy event listeners after click
      window.addEventListener('pointermove', this.handlePointerMove)
      window.addEventListener(
        'pointerup',
        () => {
          this.dragging = false
          window.removeEventListener('pointermove', this.handlePointerMove)
        },
        { once: true }
      )
    },
    handlePointerMove(event: PointerEvent) {
      if (!this.dragging) {
        return
      }

      const svg = this.$refs.svg as SVGElement | undefined
      if (!svg) {
        return
      }
      const svgRect = svg.getBoundingClientRect()

      const dx = event.clientX - this.lastMousePosition.x
      const dy = event.clientY - this.lastMousePosition.y

      const viewBoxDx = (dx * this.viewBox.width) / svgRect.width
      const viewBoxDy = (dy * this.viewBox.height) / svgRect.height

      this.viewBox.left -= viewBoxDx
      this.viewBox.top -= viewBoxDy

      this.lastMousePosition = { x: event.clientX, y: event.clientY }
    },
    /**
     * <path> value for the isolator
     * @param conductorPoint
     */
    circleIsolatorPath(conductorPoint: WirePositionLabeledConductors) {
      const isolatorLength =
        this.lineTowerType === LineTowerType.ANCHOR_TOWER
          ? 0
          : conductorPoint.conductorSystemData.isolatorLength || 0

      return `M 0 ${isolatorLength} V ${-this.lineWidth / 2}`
    },
    /**
     * <path> value for the truss
     * @param point
     */
    circleTrussPath(point: WirePoint): string {
      // earthwire
      if (point.type === 'earthwire') {
        let vertical = 0
        let horizontal = point.x * -1
        // left/right
        if (point.x !== 0) {
          const side =
            point.x > 0 ? this.highestConductorPositions.right : this.highestConductorPositions.left
          if (side) {
            vertical = point.y - side.y
          } else {
            horizontal = 0
          }
        }

        // center
        if (point.x === 0) {
          vertical = point.y - this.towerTop
        }

        if (vertical < 0) {
          vertical = 0
          horizontal = 0
        }

        return `M 0 0 V ${vertical} H ${horizontal}`
      }

      // conductor
      const lineCap = ((point.x > 0 ? 1 : -1) * this.lineWidth) / 2
      return `M ${lineCap} 0 H ${point.x * -1}`
    },
    circleFillColor(point: WirePoint) {
      if (this.hasConductorSystemData(point)) {
        return this.systemColor(point)
      }
      return 'white'
    },
    circleStrokeColor(point: WirePoint) {
      if (point.type === 'earthwire') {
        return this.lineColorEarthwire
      }

      if (this.hasConductorSystemData(point) && point.conductorSystemData.system) {
        return 'white'
      }
      return this.lineColor
    },
    circleContextPosition(point: WirePoint) {
      // no need to add any x, parent group already has correct x
      let x = 0

      // center of circle on lower edge of crossbar
      let y = this.lineWidth / 2

      // add isolator length
      y += factorInIsolatorLength(this.lineTowerType, point)

      return `translate(${x},${y})`
    },
    labelFontColor(point: WirePoint) {
      if (point.type === 'earthwire') {
        return this.lineColorEarthwire
      }

      if (this.hasConductorSystemData(point)) {
        return 'white'
      }

      return 'black'
    }
  }
})
</script>

<template>
  <div :style="limitHeightStyle">
    <!-- SVG -->
    <div
      class="flex justify-center"
      :style="limitHeightStyle"
      @pointerenter="() => (svgHovered = true)"
      @pointerleave="() => (svgHovered = false)"
    >
      <!-- Tower SVG -->
      <div class="relative" :style="limitHeightStyle">
        <!-- Reset button -->
        <transition name="el-fade-in-linear">
          <p-btn v-if="svgHovered" class="reset-btn" size="small" @click="setDefaultView">
            Reset
          </p-btn>
        </transition>
        <svg
          ref="svg"
          :viewBox="`${viewBox.left} ${viewBox.top} ${viewBox.width} ${viewBox.height}`"
          class="block border"
          :style="{
            cursor: dragging ? 'grabbing' : 'grab',
            userSelect: 'none',
            maxHeight: `${maxHeight}`,
            width: '100%',
            touchAction: 'none',
            ...limitHeightStyle
          }"
          @mouseover="handleMouseover"
          @wheel="handleWheel"
          @pointerdown="handlePointerDown"
        >
          <!--
            Elements inside this group use 0,0 as center bottom of svg
            Also flips Y values
          -->
          <g :transform="`translate(${coordinateOffset.x}, ${coordinateOffset.y}) scale(1, -1)`">
            <!-- y-Axis -->
            <g
              v-for="y in yAxisScale"
              :key="y"
              :transform="`translate(0, ${y}) scale(1, -1)`"
              class="scale-group"
              data-vertical
            >
              <line
                :x1="xAxisMinMax.min"
                :x2="xAxisMinMax.max"
                :stroke="axisLinesColor"
                :stroke-width="y == 0 ? lineWidthCoordinates * 3 : lineWidthCoordinates"
              />
              <text
                v-if="y"
                :font-size="axisLabelFontSize"
                :x="viewBox.left - coordinateOffset.x"
                y="-0.5"
                :fill="axisLinesColor"
              >
                {{ y }} m
              </text>
            </g>

            <!-- x-Axis -->
            <g
              v-for="x in xAxisScale"
              :key="x"
              :transform="`translate(${x}, 0) scale(1, -1)`"
              class="scale-group"
              data-horizontal
            >
              <line
                :y1="yAxisMinMax.min * -1"
                :y2="yAxisMinMax.max * -1"
                :stroke="axisLinesColor"
                :stroke-width="x == 0 ? lineWidthCoordinates * 3 : lineWidthCoordinates"
              ></line>
              <text
                v-if="x"
                :font-size="axisLabelFontSize"
                y="-1"
                text-anchor="middle"
                :fill="axisLinesColor"
              >
                {{ x }} m
              </text>
            </g>

            <g :transform="`translate(0, ${towerOffsetNumber})`">
              <!-- Tower itself-->
              <line
                :y1="towerOffsetNumber * -1"
                :y2="towerTop"
                :stroke="lineColor"
                :stroke-width="lineWidth"
              />

              <!-- Tower Offset Base -->
              <line
                v-if="showTowerOffset"
                :y1="towerOffsetNumber * -1"
                :y2="0"
                :stroke="lineColorTowerOffset"
                :stroke-width="lineWidth * 5"
              />

              <!--              &lt;!&ndash; Lines to circles (wires) &ndash;&gt;-->
              <!--              <TowerSVGLines-->
              <!--                :line-width="lineWidth"-->
              <!--                :line-color="lineColor"-->
              <!--                :wire="point"-->
              <!--                v-for="(point, index) in filteredAndSortedWireData"-->
              <!--                :key="index"-->
              <!--              />-->

              <!--              &lt;!&ndash; Circles (wires) &ndash;&gt;-->
              <!--              <TowerSVGWire-->
              <!--                :wire="point"-->
              <!--                v-for="(point, index) in filteredAndSortedWireData"-->
              <!--                :key="index"-->
              <!--              />-->

              <!-- Trusses/Isolator to Conductors (Circles) -->
              <g
                v-for="(point, index) in filteredAndSortedWireData"
                :key="index"
                :transform="`translate(${point.x}, ${point.y}) scale(1,-1)`"
              >
                <!-- not drawing below 0 ("in earth") -->
                <template v-if="point.y >= 0">
                  <!-- Isolator - before truss for z level-->
                  <path
                    v-if="hasConductorSystemData(point)"
                    :d="circleIsolatorPath(point)"
                    fill="none"
                    :stroke="lineColorIsolator"
                    :stroke-width="lineWidth"
                  />
                  <!-- Truss  -->
                  <path
                    :d="circleTrussPath(point)"
                    fill="none"
                    :stroke="lineColor"
                    :stroke-width="lineWidth"
                  />
                </template>
              </g>

              <!-- Groups of wires (circles) -->
              <g
                v-for="(point, index) in filteredAndSortedWireData"
                :key="index"
                :transform="`translate(${point.x}, ${point.y}) scale(1,-1)`"
              >
                <!-- circle context -->
                <g :transform="circleContextPosition(point)">
                  <!-- Circle -->
                  <circle
                    r="2.5"
                    :fill="circleFillColor(point)"
                    :stroke="circleStrokeColor(point)"
                    stroke-width="0.2"
                    class="cursor-pointer"
                    @pointerenter="tooltipWirePoint = point"
                    @pointerleave="tooltipVisible = false"
                  />

                  <!-- Label (in Circle) -->
                  <text
                    y="0.2"
                    :fill="labelFontColor(point)"
                    font-size="3px"
                    font-weight="700"
                    text-anchor="middle"
                    dominant-baseline="middle"
                    class="pointer-events-none"
                  >
                    <template
                      v-if="
                        hasConductorSystemData(point) && point.conductorSystemData.index != null
                      "
                    >
                      {{ conductorLabelFn(point.conductorSystemData.index) }}
                    </template>

                    <template v-else>
                      {{ point.label }}
                    </template>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <!-- Caption -->
    <div v-if="caption" class="text-center text-gray-500">
      <small>{{ caption }}</small>
    </div>

    <!-- Tooltip -->
    <el-popover
      :visible="tooltipVisible"
      :teleported="false"
      :virtual-ref="tooltipVirtualRef"
      virtual-triggering
      transition="none"
      :popper-style="{ maxWidth: '600px' }"
      popper-class=" !border-gray-300 !p-2"
      placement="top"
      width="auto"
    >
      <TowerSVGHoverInformation
        v-if="tooltipWirePoint"
        :tower-offset="towerOffsetNumber"
        :line-tower-type="lineTowerType"
        :wire-point="tooltipWirePoint"
        class="w-full"
      />
    </el-popover>
  </div>
</template>

<style scoped lang="css">
.reset-btn {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.scale-group {
  pointer-events: none;
}
</style>
