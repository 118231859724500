import { MediaLayerContext } from '@/components/map/layer/util'
import { Modify, Select, Snap } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector'
import { LayerId } from '@/components/map'
import { click, pointerMove, singleClick } from 'ol/events/condition'
import { Collection, Feature as FeatureOl } from 'ol'
import { LineString, MultiLineString } from 'ol/geom'
import VectorSource from 'ol/source/Vector'
import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { useMapSelection } from '@/components/map/composables'
import { onUnmounted, ref } from 'vue'
import { useMediaGroupStore } from '@/stores/media-group'
import { mediaStyleDefault, mediaStyleHover, mediaStyleSelect } from '@/components/map/style/media'
import { Layer } from 'ol/layer'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { ModifyEvent } from 'ol/interaction/Modify'
import { featureIsDraft, FeaturePropEnum, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import BaseEvent from 'ol/events/Event'
import { MEDIA_GROUP_LAYER_Z_INDEX } from '@/components/map/controls/layers/types'
import useHoverInformation from '@/components/map/composables/useHoverInformation'

type MediaModifyContext = {
  lines: FeatureOl[]
  mediaGroup: FeatureOl
}

/**
 * Adds interaction on top of MediaGroup to show/select/modify its LineStrings/Media
 */
export function useMediaEdit(ctx: MediaLayerContext, mediaGroupSelect: Select) {
  const { registerSelectInteraction } = useMapSelection()
  /**
   * Create layer and hover/select/modify for Media-LineStrings
   */
  const { mediaLayer, loadLineStrings, source } = setupMediaLayer()
  ctx.map.addLayer(mediaLayer)
  onUnmounted(() => ctx.map.removeLayer(mediaLayer))

  const mediaHover = setupMediaHover(mediaLayer, ctx)
  const mediaSelect = setupMediaSelect(mediaLayer)
  const mediaModify = setupMediaModify(ctx, source, mediaSelect)
  useMapInteraction(mediaHover)
  useMapInteraction(mediaSelect)
  useMapInteraction(mediaModify.modify)
  useMapInteraction(mediaModify.snap)

  ctx.mediaSelect = mediaSelect
  registerSelectInteraction(mediaSelect) // for context menu

  /**
   * MediaGroup select
   * - populate Layer Source with LineStrings/Media of selected MediaGroup
   */
  const handleMediaGroupSelect = () => {
    const selected = mediaGroupSelect.getFeatures().getArray().slice()

    mediaModify.toggle(false)
    mediaSelect.getFeatures().clear()
    mediaLayer.setVisible(false)
    const mediaGroup = selected[0] as FeatureOl<MultiLineString>
    // only 1 item and item cannot be draft
    if (selected.length !== 1 || featureIsDraft(mediaGroup)) {
      return
    }
    mediaLayer.setVisible(true)
    loadLineStrings(mediaGroup)
  }
  mediaGroupSelect.getFeatures().on('add', handleMediaGroupSelect)
  mediaGroupSelect.getFeatures().on('remove', handleMediaGroupSelect)

  /**
   * Media Select
   * - activate/deactivate MediaGroupSelect
   * - toggle modify of Media
   */
  const handleMediaSelect = () => {
    const selected = mediaSelect.getFeatures().getArray().slice()

    mediaGroupSelect.setActive(false)
    mediaModify.toggle(false)
    if (selected.length === 0) {
      mediaGroupSelect.setActive(true)
    }
    if (selected.length === 1) {
      mediaModify.toggle({
        lines: selected,
        mediaGroup: mediaGroupSelect.getFeatures().getArray()[0]
      })
    }
  }
  mediaSelect.getFeatures().on('add', handleMediaSelect)
  mediaSelect.getFeatures().on('remove', handleMediaSelect)
  return mediaSelect
}

/**
 * Layer
 */
function setupMediaLayer() {
  const mediaContext = ref<{ mediaGroupId: string; lines: FeatureOl<LineString>[] } | null>(null)
  const source = new VectorSource()
  const mediaLayer = new VectorLayer({
    source,
    properties: { id: LayerId.MEDIA },
    visible: false,
    // default style for drawn elements
    style: mediaStyleDefault,
    zIndex: MEDIA_GROUP_LAYER_Z_INDEX + 1 // make it higher than MediaGroupLayer to bring Media to the front
  })

  function loadLineStrings(multi: FeatureOl<MultiLineString>) {
    const lines = extractLineStrings(multi)
    source.clear()
    source.addFeatures(lines)
    mediaContext.value = {
      mediaGroupId: multi.getId() as string,
      lines
    }
  }
  return { mediaLayer, source, loadLineStrings }
}

/**
 * Hover
 */
function setupMediaHover(mediaLayer: Layer<any>, ctx: MediaLayerContext) {
  const hoverInteraction = new Select({
    condition: pointerMove,
    toggleCondition: () => false,
    filter: (feature) => {
      // Do not highlight selected features
      return !ctx.selectedFeatures.value.includes(feature)
    },
    // hitTolerance: HitTolerance,
    layers: [mediaLayer],
    style: mediaStyleHover
  })

  // Change cursor to pointer on hover
  const mapMode = useMapMode()
  hoverInteraction.on('select', (event) => {
    ctx.map.getViewport().style.cursor =
      event.selected.length > 0 && mapMode.value === MapMode.EDIT ? 'pointer' : ''
  })

  return hoverInteraction
}

/**
 * Select
 */
function setupMediaSelect(mediaLayer: Layer<any>) {
  const mediaGroupStore = useMediaGroupStore()
  return new Select({
    condition: (e) => click(e) && !mediaGroupStore.drawing,
    // style for selected elements
    style: mediaStyleSelect,
    layers: [mediaLayer]
  })
}

/**
 * Modify
 */
function setupMediaModify(ctx: MediaLayerContext, source: VectorSource, mediaSelect: Select) {
  const { disableHoverInformation } = useHoverInformation()
  const modifiable = new Collection<FeatureOl>()
  const mediaGroupStore = useMediaGroupStore()
  const modify = new Modify({
    features: modifiable,
    deleteCondition: singleClick
  })
  const snap = new Snap({
    source: ctx.layer.getSource() || undefined,
    edge: false,
    vertex: true
  })

  /**
   * While modifying disable hover information
   */
  modify.on('modifystart', () => {
    disableHoverInformation.value = true
  })

  /**
   * Handle modification
   * 1. Restore disabled hover information
   * 2. propagate modification to "Parent" MediaGroup and save
   */
  let mediaModifyContext: MediaModifyContext | false = false
  modify.on('modifyend', async (e: ModifyEvent) => {
    disableHoverInformation.value = false

    if (mediaModifyContext === false) {
      return
    }
    const feature = e.features.item(0)
    const lineStringModified = feature.getGeometry() as LineString
    const lineStringIndex = feature.get(FeaturePropEnum.mediaGroupIndex)
    const lineStringMediaGroupId = feature.get(FeaturePropEnum.mediaGroupId)

    // check matching mediaGroupId
    if (lineStringMediaGroupId !== mediaModifyContext.mediaGroup.getId()) {
      return
    }

    // check mediaGroup exists && LineString index
    const mediaGroup = mediaGroupStore.findById(lineStringMediaGroupId)
    if (!mediaGroup || !mediaGroup.mediaGeometry.coordinates[lineStringIndex]) {
      return
    }

    // update coord at index and map back to project CRS
    mediaGroup.mediaGeometry.coordinates[lineStringIndex] = lineStringModified
      .getCoordinates()
      .map((c) => ctx.mapToProject(c))
    await mediaGroupStore.save(mediaGroup)

    // After save GEOJson changes we lose reference -> fix is to "reselect" feature by finding it by id
    toggle(false)
    const newFeat = source.getFeatures().find((feat) => feat.getId() === feature.getId())
    if (newFeat) {
      mediaSelect.getFeatures().clear()
      mediaSelect.getFeatures().push(newFeat)
      mediaSelect.dispatchEvent(new BaseEvent('select'))
    }
  })
  function toggle(context: MediaModifyContext | false) {
    mediaModifyContext = context
    if (context) {
      modifiable.extend(context.lines)
      modify.setActive(true)
      snap.setActive(true)
    } else {
      modifiable.clear()
      modify.setActive(false)
      snap.setActive(false)
    }
  }
  return { modify, snap, toggle }
}

/**
 * Get LineStrings of MediaGroup MultiLineString
 * @param multiline
 */
export function extractLineStrings(multiline: FeatureOl<MultiLineString>): FeatureOl<LineString>[] {
  const features: FeatureOl<LineString>[] = []
  multiline
    .getGeometry()
    ?.getLineStrings()
    .forEach((line, index) => {
      const feat = new FeatureOl({ geometry: line, type: 'LineString' })
      feat.setProperties({
        [FeaturePropEnum.type]: FeatureTypeEnum.media,
        [FeaturePropEnum.mediaGroupIndex]: index,
        [FeaturePropEnum.mediaGroupId]: multiline.getId()
      })
      feat.setId(`${index}_${multiline.getId()}`)
      features.push(feat)
    })
  return features
}
