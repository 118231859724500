<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import TowerTypeSelectField from '@/components/tower-type/TowerTypeSelectField.vue'
import TowerAllocationSelectField from '@/components/tower/TowerAllocationSelectField.vue'
import { useProject } from '@/composables/useProject'
import { ConductorTypeId } from '@/model'
import { OverheadLine, TowerRequest } from '@gridside/hsb-api'
import { v4 } from 'uuid'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { RouteParams } from '@/router/routeParams'
import fields from '@/config/fields/overhead-line'
import towerFields from '@/config/fields/tower'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'OverheadLineForm',
  components: {
    ConductorTypeSelectField,
    TowerAllocationSelectField,
    TowerTypeSelectField,
    DetailPanel
  },

  data: () => ({
    earthWiresCount: 0,
    saving: false,
    fieldConfig: fields,
    towerFieldConfig: towerFields,
    towerEarthWires: [] as ConductorTypeId[]
  }),

  setup() {
    const { overheadLineStore, overheadLineId } = useOverheadLine()
    const { projectId } = useProject()
    return { overheadLineStore, overheadLineId, projectId }
  },

  computed: {
    create(): boolean {
      return !!this.$route.meta.create
    },
    RouteParams() {
      return RouteParams
    },
    item(): Partial<OverheadLine> | undefined {
      const draft = this.overheadLineStore.overheadLineDraft?.overheadLine

      if (draft) {
        return draft
      } else if (this.overheadLineId) {
        return this.overheadLineStore.findById(this.overheadLineId)?.overheadLine
      } else {
        return undefined
      }
    },
    title() {
      return this.create ? 'Freileitung erstellen' : 'Freileitung bearbeiten'
    }
  },

  mounted() {
    // Prevent create mode when no draft is present (i.e. when reloading the create route)
    const draft = this.overheadLineStore.overheadLineDraft
    if ((this.create && !draft) || (!this.create && !this.item)) {
      this.$router.push({ name: 'project-map' })
    }
  },

  methods: {
    async close() {
      await this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'overhead-lines-index' }
      )
    },

    async save(values: any) {
      if (!this.item) {
        return
      }

      const draft = this.overheadLineStore.overheadLineDraft
      const item = { ...values, id: this.item.id, project: this.projectId }
      this.saving = true
      try {
        if (this.create && draft?.path && values.tower) {
          // save overhead line
          await this.overheadLineStore.save(item)

          // create and save towers
          const towers: TowerRequest[] = draft.path.map((coord, index) => {
            return {
              id: v4(),
              x: coord.x,
              y: coord.y,
              name: `M${index + 1}`,
              overheadLine: item.id,
              position: index + 1,
              earthResistivity: values.tower.earthResistivity,
              in: {
                ...values.tower,
                earthwires: values.tower.earthwires || []
              }
            }
          })
          await this.overheadLineStore.towersReplaceAll({ list: towers }, item.id)
        } else {
          await this.overheadLineStore.save(item)
        }
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="close" data-test="overheadline-detail">
    <p-form
      v-if="item"
      :item="item"
      cancelable
      :saving="saving"
      :submit-label="create ? 'Speichern' : undefined"
      @cancel="close"
      @submit="save"
    >
      <p-field v-bind="fieldConfig.name" />
      <p-field v-bind="fieldConfig.operator" />
      <p-field v-bind="fieldConfig.description" :rows="4" />
      <p-field class="hidden" name="path" />

      <p-form-section v-if="create" title="Mastkonfiguration">
        <TowerTypeSelectField
          v-bind="towerFieldConfig.type"
          name="tower.type"
          v-model:earthwire-count="earthWiresCount"
        />
        <TowerAllocationSelectField v-bind="towerFieldConfig.allocation" name="tower.allocation" />
        <!-- earthwire allocation -->
        <ConductorTypeSelectField
          v-for="n in earthWiresCount"
          :key="n"
          :field-config="towerFieldConfig.earthwires"
          :index="n - 1"
          :conductors="towerEarthWires"
          :name="`tower.earthwires[${n - 1}]`"
        />

        <div class="grid grid-cols-2 gap-6">
          <p-field v-bind="towerFieldConfig.offset" name="tower.offset" />
          <p-field v-bind="towerFieldConfig.earthResistivity" name="tower.earthResistivity" />
        </div>
      </p-form-section>
    </p-form>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
