<script lang="ts">
import { useMapControlState } from '@/components/map/composables/useMapControlState'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { defineComponent } from 'vue'
import MapControlHeading from '../MapControlHeading.vue'
import LayersTree from './LayersTree.vue'

export default defineComponent({
  name: 'LayersControl',
  components: {
    LayersTree,
    PopoverPanel,
    MapControlHeading,
    PopoverButton,
    Popover
  },

  setup() {
    const { expanded } = useMapControlState('layers')
    return { expanded }
  }
})
</script>

<template>
  <Popover as="div" v-slot="{}">
    <div data-test="map-control-layers">
      <PopoverButton
        class="w-full z-[1000] background trigger-button"
        @click="expanded = !expanded"
        @keydown.enter="expanded = !expanded"
      >
        <div class="w-64 h-9 background" :class="{ 'trigger--expanded': expanded }">
          <MapControlHeading class="px-4" :expanded="expanded">Ebenen</MapControlHeading>
        </div>
      </PopoverButton>
      <transition
        enter-active-class="transition duration-150 origin-top"
        enter-from-class="scale-y-0 opacity-0"
        enter-to-class="scale-y-100 opacity-100"
        leave-active-class="transition duration-150 origin-top"
        leave-from-class="scale-y-100  "
        leave-to-class="scale-y-0 opacity-0 "
      >
        <PopoverPanel
          v-show="expanded"
          class="panel background w-64"
          :class="{ 'panel--expanded': expanded }"
          static
        >
          <LayersTree />
        </PopoverPanel>
      </transition>
    </div>
  </Popover>
</template>

<style scoped lang="css">
.background {
  @apply bg-gray-50 bg-opacity-80 backdrop-blur-lg rounded-md shadow-lg;
}

.trigger--expanded {
  @apply rounded-b-none bg-opacity-60;
}

.panel {
  @apply px-2 py-2;
}

.panel--expanded {
  @apply rounded-t-none;
}
</style>
