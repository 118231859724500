<script lang="ts">
import { defineComponent } from 'vue'
import { v4 } from 'uuid'

import EntityTableView from '@/components/common/EntityTableView.vue'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { useProject } from '@/composables/useProject'
import { fieldLabel, fieldUnit } from '@/config/fields'
import {
  ConductorAllocationId,
  formatTowerName,
  LineTowerTypeTranslations,
  TowerId,
  TowerTypeId
} from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { useTowerTypeStore } from '@/stores/tower-type'
import { TowerRequest, TowerResponse } from '@gridside/hsb-api'
import { useRoute } from 'vue-router'
import { useDelete } from '@/composables/crud-helpers/useDelete'

export default defineComponent({
  name: 'TowerListView',
  components: { EntityTableView },
  setup() {
    const towerTypeStore = useTowerTypeStore()
    const route = useRoute()
    const overheadLineStore = useOverheadLineStore()
    const allocationStore = useConductorAllocationStore()

    const { projectId } = useProject()
    const { overheadLineRecord } = useOverheadLine()
    const { deleteItems } = useDelete(overheadLineStore.towerDelete, {
      check: (items: TowerResponse[]) => items.some((item) => item.id === route.params.id),
      routeName: 'overhead-line-towers'
    })
    async function duplicateItems(towers: TowerResponse[]) {
      for (const item of towers) {
        const newItem: TowerRequest = {
          ...item,
          id: v4(),
          name: item.name ? item.name + ' - Kopie' : undefined,
          position: item.position + 1
        }
        await overheadLineStore.towerSave(newItem)
      }
    }

    return {
      allocationStore,
      overheadLineStore,
      projectId,
      towerTypeStore,
      overheadLineRecord,
      fieldLabel,
      fieldUnit,
      deleteItems,
      duplicateItems
    }
  },

  computed: {
    items(): TowerResponse[] {
      return Object.values(this.overheadLineRecord.towersById)
    },

    searchStrings(): string[] {
      return Object.values(this.overheadLineRecord.towersById).map((item) =>
        [
          this.formatTowerName(item.id),
          this.formatTowerType(item.in.type),
          this.$n(item.earthResistivity || 0),
          this.$n(item.in.offset || 0)
        ]
          .join(' ')
          .toLowerCase()
      )
    },

    towerId(): TowerId {
      return this.$route.params[RouteParams.TowerId] as string
    }
  },

  mounted() {
    this.towerTypeStore.ensureLoaded()
    this.towerTypeStore.ensureLoadedByProject(this.projectId)
    this.allocationStore.ensureLoaded(this.projectId)

    this.overheadLineStore.towersSelection = this.towerId ? [this.towerId] : []
  },

  methods: {
    formatAllocation(id: ConductorAllocationId | undefined): string {
      const allocation = this.allocationStore.findById(id)
      return allocation ? allocation.name : '?'
    },

    formatTowerName(id: TowerId): string {
      const tower = this.overheadLineStore.findTowerById(id)
      return tower ? formatTowerName(tower) : ''
    },

    formatTowerType(id: TowerTypeId): string {
      return this.towerTypeStore.findById(id)?.name || '(unbekannt)'
    },

    formatLineTowerType(id: TowerTypeId): string {
      return this.towerTypeStore.findById(id)?.lineTowerType
        ? LineTowerTypeTranslations[this.towerTypeStore.findById(id)!.lineTowerType]
        : 'unbekannt'
    },

    async onCreate() {
      this.overheadLineStore.towersSelection = []
      this.$router.push({ name: 'overhead-line-tower-create' })
    },

    onSelectionChange(selectedIds: TowerId[]) {
      if (selectedIds.length === 0) {
        this.$router.push({ name: 'overhead-line-towers' })
      } else {
        this.$router.push({ name: 'overhead-line-tower-edit', params: { towerId: selectedIds[0] } })
      }
    },

    asTower(item: any): TowerResponse {
      return item
    },

    unitString(value: number | undefined, fieldUnit: string): string {
      return typeof value === 'number' ? this.$n(value) + ' ' + fieldUnit : '&ndash;'
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="overheadLineStore.towersSelection"
    allow-duplicate
    create-label="Neuer Mast"
    confirm-delete-label="Mast löschen"
    default-sort="position"
    :items="items"
    :loading="overheadLineStore.loading"
    no-header
    :search-strings="searchStrings"
    title="Masten"
    @create="onCreate"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
    @update:selection="onSelectionChange"
  >
    <template #columns>
      <!-- Position -->
      <el-table-column
        v-slot="{ row }"
        prop="position"
        label="Pos."
        sortable
        width="110px"
        class-name="font-semibold"
      >
        {{ asTower(row).position }}
      </el-table-column>

      <!-- Name -->
      <el-table-column prop="name" :label="fieldLabel('tower.name')" sortable>
        <template v-slot:default="{ row }">
          <span :class="{ 'text-gray-400': !asTower(row).name }">
            {{ formatTowerName(asTower(row).id) }}
          </span>
        </template>
      </el-table-column>

      <!-- TowerType -->
      <el-table-column prop="type" :label="fieldLabel('tower.type')" sortable>
        <template v-slot:default="{ row }">
          {{ formatTowerType(asTower(row).in.type) }}
          <template v-if="asTower(row).out">
            /
            <br />
            {{ formatTowerType(asTower(row).out!.type) }}
          </template>
        </template>
      </el-table-column>

      <!-- Conductor Allocation -->
      <el-table-column
        v-slot="{ row }"
        prop="conductorAllocationIn"
        :label="fieldLabel('tower.allocation')"
        sortable
      >
        {{ formatAllocation(asTower(row).in.allocation) }}
        <template v-if="asTower(row).out">
          /
          <br />
          {{ formatAllocation(asTower(row).out?.allocation) }}
        </template>
      </el-table-column>

      <!-- Offset -->
      <el-table-column
        v-slot="{ row }"
        prop="offset"
        :label="fieldLabel('tower.offset')"
        width="10ch"
        align="center"
        sortable
      >
        {{ unitString(asTower(row).in.offset, fieldUnit('tower.offset')) }}
        <template v-if="asTower(row).out">
          /
          <br />
          {{ unitString(asTower(row).out?.offset, fieldUnit('tower.offset')) }}
        </template>
      </el-table-column>

      <!-- Earth Resistivity -->
      <el-table-column
        v-slot="{ row }"
        prop="earthResistivity"
        :label="fieldLabel('tower.earthResistivity')"
        width="20ch"
        align="center"
        sortable
      >
        {{ unitString(asTower(row).earthResistivity, fieldUnit('tower.earthResistivity')) }}
      </el-table-column>
    </template>

    <template #confirm-delete="{ items }">
      <p v-if="items.length === 1">
        Wollen Sie den Mast
        <b>{{ formatTowerName(asTower(items[0]).id) }}</b>
        wirklich löschen?
      </p>
      <template v-else>
        <p>Wollen Sie diese Masten wirklich löschen?</p>
        <ul>
          <li v-for="item in items" :key="item.id">
            <b>{{ formatTowerName(asTower(item).id) }}</b>
          </li>
        </ul>
      </template>
      <p class="!mt-2 text-gray-400 text-base">
        Die Daten des abgehenden Spannfeldes werden ebenfalls gelöscht.
      </p>
    </template>
  </EntityTableView>
</template>
