<script lang="ts">
import { HsbApi } from '@/api'
import VersionInfo from '@/components/app/VersionInfo.vue'
import EntityTableView, { EntityItem } from '@/components/common/EntityTableView.vue'
import ProjectImportDialog from '@/components/project/import/ProjectImportDialog.vue'
import { Project, ProjectId, UserId } from '@/model'
import { useAppAbility } from '@/permissions/useAppAbility'
import { RouteParams } from '@/router/routeParams'
import { useProjectStore } from '@/stores/project'
import { useUserStore } from '@/stores/user'
import { triggerDownload } from '@/util/helpers'
import { useAuthentication } from '@prionect/ui'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/de'
import { ElLoading } from 'element-plus'
import { defineComponent } from 'vue'

const ProjectApi = HsbApi.projects

export default defineComponent({
  name: 'ProjectsView',
  components: { VersionInfo, ProjectImportDialog, EntityTableView },

  data: () => ({
    progressTitle: 'Bitte warten',
    progressText: 'Die Operation wird durchgeführt',
    projectLoading: false,
    showProgress: false,
    onlyMine: true,
    importDialog: false
  }),

  setup() {
    const { can } = useAppAbility()
    const projectStore = useProjectStore()
    const userStore = useUserStore()
    projectStore.ensureLoaded()

    dayjs.locale('de')
    dayjs.extend(relativeTime)
    dayjs.extend(utc)

    return { can, projectStore, userStore }
  },

  computed: {
    RouteParams() {
      return RouteParams
    },
    contextMenuExtraActions() {
      return [
        {
          label: 'exportieren',
          icon: 'DownloadIcon',
          action: this.exportProject
        }
      ]
    },

    items() {
      const { currentUser } = useAuthentication()
      if (!currentUser.value) {
        return []
      }
      const userId = currentUser.value.id
      return this.projectStore.projects
        .filter(
          (project) =>
            !this.onlyMine || project.users.includes(userId) || project.createdBy === userId
        )
        .sort((a, b) => {
          if (a.updatedAt && b.updatedAt) {
            return a.updatedAt > b.updatedAt ? -1 : 1
          }
          return 0
        })
    },

    showOnlyMineSwitch(): boolean {
      return this.can('read', 'all')
    }
  },

  methods: {
    deleteItems(projects: Project[]) {
      for (const project of projects) {
        this.projectStore.delete(project.id)
      }
    },

    async duplicateItems(projects: Project[]) {
      const title = projects.length > 1 ? 'Projekt duplizieren' : 'Projekte duplizieren'
      const text =
        projects.length > 1
          ? 'Bitte warten Sie, das Projekt wird dupliziert...'
          : 'Bitte warten Sie, die Projekte werden dupliziert'
      this.progressTitle = title
      this.progressText = text
      this.showProgress = true
      try {
        for (const project of projects) {
          await ProjectApi.duplicateProject(project.id)
        }
      } finally {
        this.showProgress = false
      }
    },

    async editItem(id: ProjectId) {
      this.projectLoading = true
      const loadingInstance = ElLoading.service({
        lock: false,
        fullscreen: false,
        target: '.app-content',
        text: 'Projekt wird geladen...',
        background: 'var(--tw-color-gray-50)'
      })

      try {
        await this.$router.push({ name: 'project-base', params: { [RouteParams.ProjectId]: id } })
      } finally {
        this.projectLoading = false
        loadingInstance.close()
      }
    },

    async exportProject(project: EntityItem) {
      this.progressTitle = 'Projekt-Export'
      this.progressText = 'Bitte warten, das Projekt wird exportiert...'
      this.showProgress = true
      try {
        const response = await ProjectApi.exportProjectRaw({ projectId: project.id })
        const exportData = await response.raw.json()
        triggerDownload(
          JSON.stringify(exportData),
          `HSB-${project.name}-${dayjs().format('YYYY-MM-DD-HHmm')}.json`,
          'application/json'
        )
      } finally {
        this.showProgress = false
      }
    },

    formatRelativeDate(date: Date) {
      return dayjs(date).utc(true).fromNow()
    },

    formatUserName(id: UserId) {
      const { currentUser } = useAuthentication()
      if (id === currentUser?.value.id) {
        return 'Ihnen'
      } else {
        return this.userStore.findNameById(id)
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    v-if="!projectLoading"
    v-model:selection="projectStore.selection"
    :allow-duplicate="true"
    :extra-actions="contextMenuExtraActions"
    :items="items"
    :loading="projectStore.loading"
    :search-properties="['name', 'customer']"
    create-label="Neues Projekt anlegen"
    title="Projekte"
    @create="$router.push({ name: 'project-create' })"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
    @edit="editItem"
  >
    <template #empty>
      <p class="text-gray-500 text-lg mb-6">
        Sie haben bisher kein Projekt erstellt oder sind keinem Projekt zugeordnet.
      </p>

      <p-btn @click="$router.push({ name: 'project-create' })">Neues Projekt anlegen</p-btn>
    </template>

    <template #extra-tools>
      <p-btn @click="importDialog = true">Import</p-btn>
      <div v-if="showOnlyMineSwitch" class="mr-8">
        <p-switch-input v-model="onlyMine" label="nur meine Projekte" name="onlyMine" />
      </div>
    </template>

    <template #columns>
      <el-table-column
        class-name="font-semibold"
        label="Projektname"
        prop="name"
        sortable
      ></el-table-column>
      <el-table-column label="Kunde" prop="customer" sortable></el-table-column>
      <el-table-column label="zuletzt bearbeitet" prop="updatedAt" sortable>
        <template #default="{ row }">
          <div v-if="row.locked" class="text-sm leading-normal">
            <span class="font-semibold text-primary-600 p-1 -ml-1 rounded bg-primary-100">
              wird bearbeitet
            </span>
            <br />
            <span class="text-gray-400">von {{ formatUserName(row.lockedBy) }}</span>
          </div>
          <div v-else class="text-sm leading-normal">
            {{ formatRelativeDate(row.updatedAt) }}
            <br />
            <span class="text-gray-400">von {{ formatUserName(row.updatedBy) }}</span>
          </div>
        </template>
      </el-table-column>
    </template>

    <template #footer><VersionInfo /></template>
  </EntityTableView>

  <!-- Import project -->
  <ProjectImportDialog v-model="importDialog"></ProjectImportDialog>

  <!-- Duplicate project progress -->
  <p-dialog :show="showProgress" :title="progressTitle" @close="showProgress = false">
    <div class="text-gray-600">{{ progressText }}</div>
    <el-progress
      :duration="1"
      :indeterminate="true"
      :percentage="100"
      :show-text="false"
      class="mt-8"
    />
    <template #footer>
      <!-- empty -->
      <div></div>
    </template>
  </p-dialog>
</template>

<style lang="css" scoped></style>
